// import makepayment from "./Assets/Images/Projects/make-a-payment/make-a-payment.jpg"
// import makepaymentflow from "./Assets/Images/Projects/make-a-payment/make-a-payment-flow.jpg"
// import creditcard from "./Assets/Images/Projects/credit-card/small/MVPCard.png"
// import bnpl from "./Assets/Images/Projects/bnpl/bnpl.jpg"
// import lululemon from "./Assets/Images/Projects/lululemon/lululemon.jpg"
import makepayment from "./Assets/Images/Projects/vertimgs/makeapayment.jpg"
import makepaymentflow from "./Assets/Images/Projects/make-a-payment/make-a-payment-flow.jpg"
import creditcard from "./Assets/Images/Projects/vertimgs/creditcard.jpg"
import bnpl from "./Assets/Images/Projects/vertimgs/installments.jpg"
import lululemon from "./Assets/Images/Projects/vertimgs/lululemon.jpg"

export const data = [
  {
    id: "creditcard",
    title: "Credit Card Design for CCAS",
    subtitle: "Case Study",
    banner:
      creditcard,

    sections: [
      {
        type: "text",

        title:
          "Help Railsr’s Global Team launch a CCAS Product in the United States.",
        subtitle: "Background",
        text: [
          "Worked with Visa and CPI Card Group to design a physical credit card product for Railsr’s Credit Card as a Service ‘MVP’ program.",
          "This program offered a white—label credit card product for businesses looking to get to market quickly. Railsr would provide the compliance, banking partnerships, card designs, and more to make the process as simple as possible.",
          "My task was to design two credit card designs that could be used throughout the program. ",
        ],
        align: "left",

        details: [{ title: "Role", content: "Lead Product Designer" }],
        darkmode: false,
      },
      
    ],
  },
  {
    id: "make-a-payment",
    title: "Make a Payment User Flow",
    subtitle: "Case Study",
    banner:
      makepayment,

    sections: [
      {
        type: "text",

        title:
          "Create a user flow for Railsr's design kit.",
        subtitle: "Backstory",
        text: [
          "Part of Railsr's Credit Card as a Service product, the design kit was a collection of pre-approved SDK's that reduced the customer's time to market. These components would be embedded in the customer's mobile application, giving the end-user the ability to manage their card without the need for designers and developers on the customer's end.",
          "Working with Railsr's Global team, I was tasked with creating a user-flow for Making a Payment.",
        ],
        align: "left",
        
        details: [{ title: "Role", content: "Product Designer / UX | UI Designer" }],
        darkmode: false,
      },
      {
        type: "image",
        src: makepaymentflow,
        caption:
          "My goals for the project were the following:",
        
        darkmode: false,
        // details: [{ title: "Role", content: "Product Designer / UX | UI Designer" }],
      },
      {
        type: "list",

        title:
          "My goals for the project were the following:",
        subtitle: "",
        items: [
          "Conduct research on industry standard ‘Make a Payment’ user — flows.",
          "Brainstorm ways to streamline the user — journey.",
          "Design an intuitive visual narrative for each frame.",
          "Efficiently direct the user's attention to call — to — actions in the order of importance, allowing the user to subconsciously comprehend the page. ",
        ],
        
        darkmode: false,
        // details: [{ title: "Role", content: "Product Designer / UX | UI Designer" }],
      },
      
      // {
      //   type: "text",

      //   title:
      //     "Challenges I Faced:",
      //   subtitle: "",
      //   text: [
      //     "One of the setbacks of designing a streamlined user-journey, is that each frame can become crowded with required elements and text. As a designer striving for minimalism and using white-space to direct the user's attention, overcrowding became my biggest challenge.",
      //   ],
      //   align: "left",

      //   details: [],
      //   darkmode: false,
      // },
      // {
      //   type: "text",

      //   title:
      //     "Solution",
      //   subtitle: "",
      //   text: [
      //     "Using my comprehension of user experience design, I used color-contrast and font-weight to highlight interactive elements. Then I worked with the Legal team to make non-CTA elements as small as they could be to create more white-space. This gave me enough flexibility to differentiate key elements and tell a narrative on each frame.",
      //   ],
      //   align: "center",

      //   details: [],
      //   darkmode: false,
      // },
      
      
      
    ],
  },
  {
    id: "buynowpaylater",
    title: "Pay in Installments Prototype",
    subtitle: "Case Study",
    banner:
      bnpl,

    sections: [
      {
        type: "text",

        title:
          "Created a prototype and user flow for an ‘Installments as a Service’ pitch to Railsr’s executive team.",
        subtitle: "Background",
        text: [
          "Railsr was exploring the possibility of creating a flexible Buy Now Pay Later product for the e-commerce industry. It would allow customers the ability to track and edit installments, while being rewarded for on-time payments. This project was later expanded to include a credit card that’s statement balance could be broken up into installments.",
        ],
        align: "left",

        details: [{ title: "Role", content: "UX / UI Designer" }],
        darkmode: false,
      },
      {
        type: "image",
        src: makepaymentflow,
        caption:
          "My goals for the project were the following:",
        
        darkmode: false,
        // details: [{ title: "Role", content: "Product Designer / UX | UI Designer" }],
      },
      {
        type: "text",

        title: "",
        subtitle: "Design Challenge",
        text: [
          "The goal was to create two ecommerce user-flows for a fictional brand, one in a mobile web experience, and the other in an app experience. The mobile flow involved signing up, getting approved for credit, then checking out through a configurable installments module. The user would earn points for choosing installments, and the flow would end with the user successfully making an edit to the payment schedule of the installment. ",
        ],
        align: "left",

        details: [],
        darkmode: false,
      },
      
    ],
  },
  {
    id: "lululemon",
    title: "Lululemon Rewards as a Service",
    subtitle: "Case Study",
    banner:
      lululemon,

    sections: [
      {
        type: "text",

        title:
          "Created a conceptual prototype, user flow, and marketing material for a ‘Rewards as a Service’ sales pitch to Lululemon.",
        subtitle: "Background",
        text: [
          "Railsr was exploring the idea of offering a white-label rewards-system product for companies looking to expand customer touchpoints beyond the retail experience. These rewards would eventually be backed by a credit card, which would offer benefits throughout the rewards system.",
        ],
        align: "left",

        details: [{ title: "Role", content: "UX / UI Designer" }],
        darkmode: false,
      },
      {
        type: "image",
        src: makepaymentflow,
        caption:
          "My goals for the project were the following:",
        
        darkmode: false,
        // details: [{ title: "Role", content: "Product Designer / UX | UI Designer" }],
      },
      {
        type: "text",

        title: "",
        subtitle: "Design Challenge",
        text: [
          "The goal was to create a rewards experience embedded in Lululemon’s existing ecosystem that created added benefits to workout classes, blogs, and retail.",
        ],
        align: "left",

        details: [],
        darkmode: false,
      },
      {
        type: "text",

        title: "",
        subtitle: "Challenges Faced",
        text: [
          "After exploring options of a points system, Lululemon expressed concern that a points system would be too disruptive to their current experience. ",
        ],
        align: "left",

        details: [],
        darkmode: false,
      },
      {
        type: "text",

        title: "",
        subtitle: "Solution",
        text: [
          "We created a system based on exclusive access to events, retail drops, and classes, all revolving around the active-lifestyle core of the company.",
        ],
        align: "left",

        details: [],
        darkmode: false,
      },
      
    ],
  },

  
];
