import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  createRef,
} from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Carousel from "./components/carousel/index";

import { data } from "../../../../data";
import "./style.css";
import useScrollLock from "../../../../hooks/scrollLock";
import useWindowSize from "../../../../hooks/windowSize";
import { InView } from "react-intersection-observer";
import { a } from "react-spring";
import Contact from "../../../../Pages/About/components/contact";
import { FaBox } from "react-icons/fa";
import { Parallax } from 'react-scroll-parallax';

function Projects({ basename, handleNavigate, isPopup, setIsPopup }) {
  const navigate = useNavigate();
  const header = useRef();
  const [stopscroll, setStopScroll] = useState(false);
  const stopper = useRef();
  // For Projects
  const [isVisible, setIsVisible] = useState(false);
  const [projects1, setProjects1] = useState([]);
  const [projects2, setProjects2] = useState([]);
  const [projectsView, setProjectsView] = useState([]);
  const col_1 = useRef();

  //ADDING INERTIA

  const size = useWindowSize();
  const [bodyHeight, setbodyHeight] = useState();
  const [startscroll, setStartscroll] = useState(false);
  const requestref = useRef();
  const scroller = useRef();

  useEffect(() => {
    const startup = async () => {
      // For Projects
      await setProjectsView((scaleRef) =>
        Array(Math.round(data.length))
          .fill()
          .map((el, i) => scaleRef[i] || false)
      );
      await setProjects1((scaleRef) =>
        Array(Math.round(data.length / 2))
          .fill()
          .map((el, i) => scaleRef[i] || createRef())
      );
     await setProjects2((scaleRef) =>
        Array(Math.floor(data.length / 2))
          .fill()
          .map((el, i) => scaleRef[i] || createRef())
      );
       // Setting height of page for body
    if (scroller.current !== undefined) {
      document.body.style.height = `${
        scroller.current.getBoundingClientRect().height
      }px`;
      
      setbodyHeight(scroller.current.getBoundingClientRect().height);
    }
 // Asnyc that starts Scrolling
     await setStartscroll(true);
    };
   
    startup();
  }, []);

  // Math functions
  const lerp = (x, y, a) => x * (1 - a) + y * a;
  const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
  const invlerp = (x, y, a) => clamp((a - x) / (y - x));
  const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));


  let speed = 0;
  let position = 0;
  let rounded = 0;
  // Added
  let prev = 0;
  let current = 0;
  let runcount = 0;

  const Wheel = (e) => {
    // speed += e.deltaY * 0.08;
    speed += e.deltaY * 0.2;
    //Add if touch event
  };

  window.addEventListener("wheel", Wheel);

  useLayoutEffect(() => {
    const skewScrolling = () => {
      if (scroller.current !== undefined) {
        position += speed;
        speed *= 0.8;

        if (position <= 0) {
          position = 0;
          rounded = Math.round(position);
          speed = 0;
        }
        
        if (position >= scroller.current.getBoundingClientRect().height - size.height) {
          position = scroller.current.getBoundingClientRect().height - size.height;
          rounded = Math.round(position);
          speed = 0;
        }
        if (position >= scroller.current.getBoundingClientRect().height - size.height) {
          position = scroller.current.getBoundingClientRect().height - size.height;
          rounded = Math.round(position);
          speed = 0;
        }
        // Header
        // if (header.current !== undefined) {
        //   header.current.style.transform = `translate3d(0, ${
        //     current / 8
        //   }px, 0)`;
        //   header.current.style.opacity = `${1 - current / 300}`;
        // }
        // Column
        if (col_1.current !== undefined) {
          if (col_1.current.dataset.isvisible === "true") {
            if (runcount === 0) {
              prev = rounded;
            }
            current = rounded - prev;
            if (current <= 0) {
              current = 0;
            }
            col_1.current.style.transform = `translate3d(0, ${
              current / 5
            }px, 0)`;
            runcount += 1;
          }
        }
        // Projects

        projects1.map((el, i) => {
          // if (el.current !== undefined) {
      
          //   if (el.current.dataset.isvisible === "true") {
          //     if (runcount === 0) {
          //       prev = rounded;
                
          //     }
          //     current = rounded - prev;
          //     if (current <= 0) {
          //       current = 0;
          //     }
              
              // current = range(prev,size.height,-(size.height*.90),(size.height*.35),current)

              // if (i===0) {
              //   console.log("0 is: " + current)
              // }
              // if (i===0) {
              //   console.log("1 is: " + current)
              // }
            
              el.current.style.transform = `translate3d(0, ${
                (rounded - ((size.height * 1)*(i))) / 4
              }px, 0)`;



          //     runcount += 1;
          //   }
            
          // }
        });
       
        projects2.map((el, i) => {
  
          if (el.current !== undefined) {

            // if (el.current.dataset.isvisible === "true") {
            //   if (runcount === 0) {
            //     prev = rounded;
            //   }
              
            //   // if (i===0){
            //   //   console.log(runcount)
            //   // }
              
             
            //   current = rounded - prev;
              
            //   if (current <= 0) {
            //     current = 0;
            //   }
            //   el.current.style.transform = `translate3d(0, ${
            //     current / 4
            //   }px, 0)`;
              
            //   runcount += 1;
            // }
            
            // if (el.current.dataset.isvisible === "true") {
             
              el.current.style.transform = `translate3d(0, ${
                (rounded - ((size.height *1.35)*(i))) / 6
              }px, 0)`;


            }
          // }
        });

        // if (stopper.current !== undefined) {
          
        //   if (stopper.current.props.data === true) {
        //     if (speed > 0) {
        //       position = scroller.current.getBoundingClientRect().height
              
        //     }
        //   }
          
        // }

        rounded = position;

        //  Page Scroller
        scroller.current.style.transform = `translate3d(0, -${rounded}px, 0)`;
      }
      requestref.current = requestAnimationFrame(skewScrolling);
    };
    window.scrollTo(0, 0);
if (startscroll) {
  requestref.current = requestAnimationFrame(skewScrolling);
}


    return () => {
      cancelAnimationFrame(requestref.current);
    };
  }, [startscroll]);

  let linestroke = "#050505";
  let circlestroke = "#e1e1e1";

  return (
    <div id="projects">
     
      <div className="scroller" ref={scroller}>
        {/* <div className="spacer" /> */}
        <section className="banner-section">
          <div className="header-title">
            {/* <div className="header-title" ref={header}> */}
            <h1 className="header">I do amazing things with amazing people.</h1>
            {/* <h6 className="main-subtitle">UX | UI Design</h6>
            <p className="main-text">
              Lorem ipsum dolor sit amet consectetur. Sit sed ultricies nisl
              vitae ac dui mi aliquam condimentum.
            </p> */}
          </div>
        </section>
        
        <InView className="parallax-columns" onChange={setIsVisible}>
        
          <section
            className="project-col-1 parallax-column"
            data-isvisible={isVisible}
          >
            {data.map((el, i) => {
              return i < projects1.length ? (
                <a.div
                  id={el.id}
                  key={i}
                  className="project"
                  // ref={projects1[i]}
                  onClick={() =>
                    handleNavigate(`${basename}/projects/${el.id}`)
                  }
                >
                  
                  {/* <InView
                    className="project-img-c"
                    onChange={(e) => {
                      const newarray = projectsView.map((element, index) => {
                        if (index === i) {
                          return e;
                        } else {
                          return element;
                        }
                      });
                      setProjectsView(newarray);
                    }}
                  > */}
                  <div className="project-img-c">
                  
                    <img
                      className="project-img"
                      src={el.banner}
                      alt="image"
                      // data-isvisible={projectsView[i]}
                      ref={projects1[i]}
                    />        
                
                </div>
                  {/* </InView> */}
          
                  <div className="project-text-c">
                    {/* <h6 className="project-subtitle">{el.subtitle}</h6> */}
                    <div className="project-row">
                      <div className="col">
                        <h6 className="project-subtitle">{el.subtitle}</h6>
                        <h6 className="project-title">{el.title}</h6>
                      </div>
                      <div
                        className="arrow"
                        style={{ borderColor: circlestroke }}
                      >
                        <svg
                          width="67"
                          height="66"
                          viewBox="0 0 67 66"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M34 21V45"
                            stroke={linestroke}
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M43 36L34 45L25 36"
                            stroke={linestroke}
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </a.div>
              ) : null;
            })}
          </section>
          <section
            className="project-col-2 parallax-column"
            ref={col_1}
            data-isvisible={isVisible}
          >
            {data.slice(projects1.length).map((el, i) => {
              // console.log(projectsView)
              return (
                <div
                  id={el.id}
                  key={i}
                  className="project"
                  onClick={() =>
                    handleNavigate(`${basename}/projects/${el.id}`)
                  }
                >
                  {/* <InView
                    className="project-img-c"
                    onChange={(e) => {
                      const newarray = projectsView.map((element, index) => {
                        if (index === (i + projects1.length)) {
                          return e;
                        } else {
                          return element;
                        }
                      });
                      setProjectsView(newarray);
                    }}
                  > */}
                   <div className="project-img-c">
                    <img
                      className="project-img"
                      src={el.banner}
                      alt="image"
                      ref={projects2[i]}
                      // data-isvisible={projectsView[(i + projects1.length)]}
                    />
            </div>
                  {/* </InView> */}
                  <div className="project-text-c">
                    <div className="project-row">
                      <div className="col">
                        <h6 className="project-subtitle">{el.subtitle}</h6>
                        <h6 className="project-title">{el.title}</h6>
                      </div>
                      <div
                        className="arrow"
                        style={{ borderColor: circlestroke }}
                      >
                        <svg
                          width="67"
                          height="66"
                          viewBox="0 0 67 66"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M34 21V45"
                            stroke={linestroke}
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M43 36L34 45L25 36"
                            stroke={linestroke}
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
    
          {/* <section className="project-list">
          {data.map((el, i) => {
            return (
              <div
              id={el.id}
              key={i}
              className="project"
              ref={projects[i]}
              onClick={() => handleNavigate(`${basename}/projects/${el.id}`)}
            >
              <div className="project-img-c">
                <div className="tag">
                    <p className="tag-text">Case Study</p>
                </div>
                <img className="project-img" src={el.banner} alt="image" />
              </div>
              <div className="project-text-c">
               
                  <h6 className="project-subtitle">{el.subtitle}</h6>
                  <div className="project-row">
                  <div className="col">
              
                 
                 
                  <h6 className="project-subtitle">{el.subtitle}</h6>
                  <h6 className="project-title">{el.title}</h6>
                    </div>
                    <div
                      className="arrow"
                      style={{ borderColor: circlestroke }}
                    >
                      <svg
                        width="67"
                        height="66"
                        viewBox="0 0 67 66"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34 21V45"
                          stroke={linestroke}
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M43 36L34 45L25 36"
                          stroke={linestroke}
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
      
            </div>
            );
          })}
        </section> */}
        </InView>
        {/* <div className="spacer" />
        <div className="spacer" /> */}

        <Contact />
      </div>
    </div>
  );
}

export default Projects;
