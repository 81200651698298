import React, {
    Suspense,
    useEffect,
    useState,
    useLayoutEffect,
    useRef,
  } from "react";
import { a, useSpring, useSprings } from "react-spring";
import { InView } from "react-intersection-observer";
import blue from "../../../../../../Assets/Images/About/blue-background.jpg"

import "./style.css";

function Portal() {
  const [isVisible, setIsVisible] = useState();
  // let src = "https://images.squarespace-cdn.com/content/v1/5af1c54f36099b9870f769e8/1574202934951-879FKG7B16X1MJHRVU0X/IMG_4588-1.jpg?format=2500w";
  let src = blue;
  // const clip = useSpring({clipPath: isVisible ? `inset(0 0vw)` : `inset(0 7vw)`})


  return (
 
    <InView className="page-section banner" threshold={.6} onChange={setIsVisible}>
      <a.div className="banner-img-clip"  data-isvisible={isVisible} >
      {/* style={clip} */}
        <div className="banner-img-c">
          <img className="banner-img" src={src} alt="image"/>
        </div>
      </a.div>
    </InView>
   
  );
}
  
  export default Portal;